
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.items.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addItems" autocomplete="off">
                    <v-layout row wrap>
        
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="categories" v-model="items.category_id" dense  filled outlined item-text="category_name"
                                item-value="category_id" :return-object="false" :label="$store.getters.language.data.items.category_name">
                            </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="items.item_name" type="text" :label="$store.getters.language.data.items.item_name" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                        </v-flex>
                    
                        <v-flex xs12 lg2 xl2 md3 sm4 v-if="$store.getters.store.store_use_barcode == 'true'">
                            <v-text-field v-model="items.item_barcode" type="text" :label="$store.getters.language.data.items.item_barcode" dense
                                class="mx-1"  filled outlined>
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="items.item_sell_price_retail" type="number" step="any" :label="$store.getters.language.data.items.item_sell_price_retail" dense
                                class="mx-1"  filled outlined required :prefix="$store.getters.store.store_payment == 'usd' ? '$' : ''">
                            </v-text-field>
                        </v-flex>
                    
                        <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="items.item_sell_price_whole" type="number" step="any" :label="$store.getters.language.data.items.item_sell_price_whole" dense
                                class="mx-1"  filled outlined >
                            </v-text-field>
                        </v-flex> -->

                        <v-flex xs12 lg2 xl2 md3 sm4 v-if="['all', 'qist'].includes($store.getters.store.store_sell_type)">
                            <v-text-field v-model="items.item_sell_price_deposit" type="number" step="any" :label="$store.getters.language.data.items.item_sell_price_deposit" dense
                                class="mx-1" filled outlined  :prefix="$store.getters.store.store_payment == 'usd' ? '$' : ''">
                            </v-text-field>
                        </v-flex>
                    
                        <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="items.item_sell_price_shop" :prefix="$store.getters.store.store_payment == 'usd' ? '$' : ''" type="number" step="any" :label="$store.getters.language.data.items.item_sell_price_shop" dense
                                class="mx-1"  filled outlined >
                            </v-text-field>
                        </v-flex>
                 -->
                        <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.yes, value: 'enable'}, {text: $store.getters.language.data.no, value: 'disable'}]" v-model="items.item_btn_show" dense  filled outlined item-text="text"
                                item-value="value" :return-object="false" :label="$store.getters.language.data.items.item_btn_show">
                            </v-select>
                        </v-flex>
                     -->
                        <v-flex xs12 lg2 xl2 md3 sm4 v-if="$store.getters.store.store_use_expire_date == 'true'">
                            <v-text-field v-model="items.item_expire_date" type="date" :label="$store.getters.language.data.items.item_expire_date" dense
                                class="mx-1"  filled outlined>
                            </v-text-field>
                        </v-flex>
<!-- 
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.yes, value: 'true'}, {text: $store.getters.language.data.no, value: 'false'}]" v-model="items.item_available_to_shop" dense  filled outlined item-text="text"
                                item-value="value" :return-object="false" :label="$store.getters.language.data.items.item_available_to_shop">
                            </v-select>
                        </v-flex>
                     -->
                        <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-text-field v-model="items.item_available_qty" type="number" :label="$store.getters.language.data.items.item_available_qty" dense
                                class="mx-1"  filled outlined>
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4 >
                            <v-combobox v-model="items.item_unit" :items="rows" :return-object=false :label="$store.getters.language.data.items.item_unit" item-text="item_unit" item-value="item_unit" dense
                                class="mx-1"  filled outlined>
                            </v-combobox>
                        </v-flex>


                        <v-flex xs12 lg4 xl4 md4 sm4>
                            <v-textarea rows="1" auto-grow v-model="items.item_description" type="textarea" :label="$store.getters.language.data.items.item_description" dense
                                class="mx-1" filled outlined>
                            </v-textarea>
                        </v-flex>
                    
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit"  >{{$store.getters.language.data.items.add_btn}}</v-btn>
                        </v-flex>

                        
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
               <v-text-field v-model="search"  :label="$store.getters.language.data.search" hide-details style="max-width: 300px"></v-text-field>
               
            </v-card-text>
            <v-card-text>
                <v-data-table :headers="headers"   v-model="selected_rows"  :search="search" :items="rows" class="elevation-0"
                    item-key="item_id">
                    <template v-slot:[`item.item_sell_price_retail`]="{ item }">
                        <div>
                            {{ (item.item_sell_price_retail || 0).toLocaleString() }}
                            {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
                        </div>
                    </template>
                    <template v-slot:[`item.item_sell_price_whole`]="{ item }">
                        <div>
                            {{ (item.item_sell_price_whole || 0).toLocaleString() }}
                            {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
                        </div>
                    </template>
                    <template v-slot:[`item.item_sell_price_deposit`]="{ item }">
                        <div>
                            {{ (item.item_sell_price_deposit || 0).toLocaleString() }}
                            {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
                        </div>
                    </template>
                    <template v-slot:[`item.item_sell_price_shop`]="{ item }">
                        <div>
                            {{ (item.item_sell_price_shop || 0).toLocaleString() }}
                            {{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
                        </div>
                    </template>
                    <template v-slot:[`item.item_status`]="{ item }">
                        <div>
                            <v-chip color="success" small v-if="item.item_status == 'enable'"> {{ $store.getters.language.data.enabled }} </v-chip>
                            <v-chip color="error" small v-else> {{ $store.getters.language.data.disabled }} </v-chip>
                        </div>
                    </template>
                    <template v-slot:[`item.item_btn_show`]="{ item }">
                        <div>
                            <v-icon color="success" v-if="item.item_btn_show == 'enable'"> mdi-button-pointer </v-icon>
                            <v-icon color="error" v-else> mdi-button-pointer </v-icon>
                        </div>
                    </template>
                    <template v-slot:[`item.item_available_to_shop`]="{ item }">
                        <div>
                            <v-icon color="success" v-if="item.item_available_to_shop == 'true'"> mdi-check-circle </v-icon>
                            <v-icon color="error" v-else> mdi-close-circle </v-icon>
                        </div>
                    </template>

                    <template v-slot:[`item.item_expire_date`]="{ item }">
                        <div>
                            {{ item.item_expire_date ? new Date(item.item_expire_date).toISOString().split('T')[0] : '' }}
                        </div>
                    </template>

                    <template v-slot:[`item.item_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/items-list/'+item.item_id"  color="teal" class="mx-1">
                                <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectItems(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                            <!-- <v-btn color="primary" icon class="mx-1" @click="selectItemsWithImages(item)" >
                                <v-icon> mdi-image </v-icon>
                            </v-btn> -->
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteItemsList">{{$store.getters.language.data.items.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.items.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.items.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteItems(selected_items.item_id)">
                {{$store.getters.language.data.items.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>

<v-dialog v-model="images_dialog" persistent max-width="1000">
    <v-card>
        <v-card-title>
            {{ $store.getters.language.data.item_images.title }}
            <v-dialog>
                <template v-slot:activator="{on, attrs}">
                    <v-btn v-on="on" v-bind="attrs" small class="ma-1" color="primary"> <v-icon small> mdi-image </v-icon> </v-btn>
                </template>
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text>
                        <FileAttachments/>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click="images_dialog = false"> <v-icon> mdi-close </v-icon> </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
            <v-layout row wrap>
                <v-flex xs6 md4 sm3 lg2 xl2 v-for="(item, index) in selected_items.item_images" :key="index">
                    <v-card flat class="ma-1">
                        <v-card-text>
                            <v-badge @click.native="deleteItemImage(item.item_image_id)" overlap color="error" icon="mdi-delete">
                                <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" aspect-ratio="2" contain width="100px" height="100px"></v-img>
                                {{item.file_attachment_name}}
                            </v-badge>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <h3 class="my-3"> {{ $store.getters.language.data.file_attachments.title }} </h3>
            <v-layout row wrap class="mt-2">
                <v-flex xs6 md4 sm3 lg2 xl2 v-for="(item, index) in file_attachments" :key="index">
                    <v-card flat class="ma-1">
                        <v-card-text>
                            <v-badge @click.native="addItemImages(selected_items.item_id, item.file_attachment_id)" overlap color="primary" icon="mdi-plus">
                                <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" aspect-ratio="2" contain width="100px" height="100px"></v-img>
                                {{item.file_attachment_name}}
                            </v-badge>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</v-dialog>

<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/items.request.js'
    import item_images_requests from './../../requests/item_images.request.js'
    import FileAttachments from '../File_attachments/List.vue'
    export default {
        components: {
            FileAttachments
        },
        data() {
            return {
                items: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_items : {
                    item_images: []
                },
                delete_dialog: false,
                images_dialog: false,
                
            }
        },
        computed: {
            rows() {
                return this.$store.getters.items_list
            },
            categories(){
                return this.$store.getters.categories_list
            },
            store(){
                return this.$store.getters.store
            },
            file_attachments() {
                return this.$store.getters.file_attachments_list
            },
            headers() {
                return [
                    { 
                        text: this.$store.getters.language.data.items.category_name,
                        align: 'start',
                        sortable: true,
                        value: 'category_name',
                        show: true
                    },
                    { 
                        text: this.$store.getters.language.data.items.item_barcode,
                        align: 'start',
                        sortable: true,
                        value: 'item_barcode',
                        show: this.$store.getters.store.store_use_barcode == 'true' ? true : false
                    },
                    { 
                        text: this.$store.getters.language.data.items.item_name,
                        align: 'start',
                        sortable: true,
                        value: 'item_name',
                        show: true
                    },
                    
                    { 
                        text: this.$store.getters.language.data.items.item_sell_price_retail,
                        align: 'start',
                        sortable: true,
                        value: 'item_sell_price_retail',
                        show: true
                    },
                    // { 
                    //     text: this.$store.getters.language.data.items.item_sell_price_whole,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'item_sell_price_whole',
                    //     show: true
                    // },
                    { 
                        text: this.$store.getters.language.data.items.item_sell_price_deposit,
                        align: 'start',
                        sortable: true,
                        value: 'item_sell_price_deposit',
                        show: ['all', 'qist'].includes(this.$store.getters.store.store_sell_type) ? true : false
                    },
                    // { 
                    //     text: this.$store.getters.language.data.items.item_sell_price_shop,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'item_sell_price_shop',
                    //     show: true
                    // },
                    { 
                        text: this.$store.getters.language.data.items.item_expire_date,
                        align: 'start',
                        sortable: true,
                        value: 'item_expire_date',
                        show: this.$store.getters.store.store_use_expire_date == 'true' ? true : false
                    },
                    // { 
                    //     text: this.$store.getters.language.data.items.item_status,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'item_status',
                    //     show: true
                    // },
                    // { 
                    //     text: this.$store.getters.language.data.items.item_btn_show,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'item_btn_show',
                    //     show: true
                    // },
                    // { 
                    //     text: this.$store.getters.language.data.items.item_available_to_shop,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'item_available_to_shop',
                    //     show: true
                    // },
                     // { 
                    //     text: this.$store.getters.language.data.items.item_available_to_shop,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'item_available_to_shop',
                    //     show: true
                    // },
                    { 
                        text: this.$store.getters.language.data.items.item_available_qty,
                        align: 'start',
                        sortable: true,
                        value: 'item_available_qty',
                        show: true
                    },   
                    { 
                        text: this.$store.getters.language.data.items.item_unit,
                        align: 'start',
                        sortable: true,
                        value: 'item_unit',
                        show: true
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'item_id',
                        show: true
                    }
                ].filter(obj => obj.show)
            }
        },
        mounted(){

        },
        methods: {
            addItems() {
                this.loading_btn = true
                this.items.item_status = 'enable'
                requests.createItems(this.items).then(r => {
                    if (r.status == 200) {
                        this.items = {}
                        this.$store.state.items_list.push(r.data.new_data)
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteItems(item_id) {
                requests.deleteItems(item_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.$store.state.items_list = this.$store.state.items_list.filter(f =>{
                            return f.item_id != item_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            deleteItemsList() {
                let ids = this.selected_rows.map(m => m.item_id)
                requests.deleteItemsList(ids).then(r => {
                    if (r.status == 200) {
                        this.$store.state.items_list = this.$store.state.items_list.filter(f =>{  
                            return ids.indexOf(f.item_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                        this.selected_rows = [];
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            selectItems(i) {
                this.selected_items = {
                    item_images: [],
                    ...i
                }
                this.delete_dialog = true
            },
            selectItemsWithImages(i) {
                this.selected_items = {
                    item_images: [],
                    ...i
                }
                item_images_requests.getItemImagesByColumn('item_id', i.item_id).then(r => {
                    this.selected_items.item_images = r.data.rows;
                })
                this.images_dialog = true;
            },
            addItemImages(item_id, file_attachment_id) {
                const image = this.selected_items.item_images.find(obj => obj.file_attachment_id == file_attachment_id);
                if(!image) {
                    item_images_requests.createItemImages({item_id, file_attachment_id}).then(r => {
                        if(r.status == 200) {
                            this.selected_items.item_images.push(r.data.new_data);
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.add_success,
                                color: 'success'
                            }
                        } else {
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.failed,
                                color: 'error'
                            }
                        }
                    })
                } else {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.already_exist,
                        color: 'info'
                    }
                }
            },
            deleteItemImage(item_image_id) {
                item_images_requests.deleteItemImages(item_image_id).then(r => {
                    this.selected_items.item_images = this.selected_items.item_images.filter(obj => obj.item_image_id != item_image_id);
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.delete_success,
                        color: 'success'
                    }
                }).catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
            } 
        },
    }
</script>
                    